'use client'

export default function ErrorPage() {
  return (
    <>
      <h1>Error!</h1>
      <p>Something went wrong!</p>
    </>
  )
}
